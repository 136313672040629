import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";

// -------------------blogs-----------------
import EditBlog from "layouts/pages/1-blogs/edit-blog";
import BlogsPage from "layouts/pages/1-blogs/blogs-page";
import AddNewBlog from "layouts/pages/1-blogs/new-blog";

// -------------------Exam-----------------
// import EditExam from "layouts/pages/2-exams/edit-exam";
// import ExamsPage from "layouts/pages/2-exams/exams-page";
// import AddNewExam from "layouts/pages/2-exams/new-exam";

// import Settings from "layouts/pages/account/settings";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
// ------------Authentification--------------
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import ResetCover from "layouts/authentication/reset-password/cover";
import NewUser from "layouts/pages/users/new-user";

import SignUpCover from "layouts/authentication/sign-up/cover";

import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import SchoolIcon from "@mui/icons-material/School";
import ArticleIcon from "@mui/icons-material/Article";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import WorkIcon from "@mui/icons-material/Work";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CategoryIcon from "@mui/icons-material/Category";
import ComputerIcon from "@mui/icons-material/Computer";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

// Images
import profilePicture from "assets/images/team-3.jpg";
//Pages
import AddNewExam from "layouts/pages/2-exams/new-exam";
import EditExam from "layouts/pages/2-exams/edit-exam";
import ExamsPage from "layouts/pages/2-exams/exams-page";
import AddNewHomeSlider from "layouts/pages/4-homeSlider/new-homeSlider";
import EditHomeSlider from "layouts/pages/4-homeSlider/edit-homeSlider";
import HomeSlidersPage from "layouts/pages/4-homeSlider/homeSliders-page";
import AddNewPortfolio from "layouts/pages/5-portfolio/new-portfolio";
import PortfolioPage from "layouts/pages/5-portfolio/portfolio-page";
import AddNewCategory from "layouts/pages/6-categories/new-category";

const routes = [
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
      {
        name: "Users",
        key: "users",
        collapse: [
          {
            name: "New User",
            key: "new-user",
            route: "/pages/users/new-user",
            component: <NewUser />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up/cover",
            component: <SignUpCover />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
    ],
  },
  { type: "title", title: "Pages", key: "title-pages" },

  //-------------- Blogs---------------------
  {
    type: "collapse",
    name: "Blog",
    key: "blog",
    icon: <ArticleIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Blog",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-blog",
        route: "/dashboard/blogs/new-blog",
        component: <AddNewBlog />,
      },
      {
        name: "Edit Blog",
        key: "edit-blog",
        route: "/dashboard/blogs/edit-blog",
        component: <EditBlog />,
      },
      {
        name: "Blogs Page",
        key: "blog-page",
        route: "/dashboard/blogs/blogs-page",
        component: <BlogsPage />,
      },
    ],
  },
  //-------------- Exam---------------------
  {
    type: "collapse",
    name: "Exam",
    key: "exam",
    icon: <SchoolIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Exam",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-exam",
        route: "/dashboard/2-exams/new-exam",
        component: <AddNewExam />,
      },
      {
        name: "Edit Exam",
        key: "edit-exam",
        route: "/dashboard/2-exams/edit-exam",
        component: <EditExam />,
      },
      {
        name: "Exams Page",
        key: "exams-page",
        route: "/dashboard/2-exams/exams-page",
        component: <ExamsPage />,
      },
    ],
  },
  //-------------- Home Slider---------------------
  {
    type: "collapse",
    name: "Home Slider",
    key: "homslider",
    icon: <SlideshowIcon fontSize="medium" />,
    collapse: [
      {
        name: "New homeSlider",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-homeSlider",
        route: "/dashboard/4-homeSlider/new-homeSlider",
        component: <AddNewHomeSlider />,
      },
      {
        name: "Edit homeSlider",
        key: "edit-homeSlider",
        route: "/dashboard/4-homeSlider/edit-homeSlider",
        component: <EditHomeSlider />,
      },
      {
        name: "homeSlider Page",
        key: "homeSliders-page",
        route: "/dashboard/4-homeSlider/homeSliders-page",
        component: <HomeSlidersPage />,
      },
    ],
  },
  //-------------- Portfolio---------------------
  {
    type: "collapse",
    name: "Portfolio",
    key: "portfolio",
    icon: <WorkIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Portfolio",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-portfolio",
        route: "/dashboard/5-portfolio/new-portfolio",
        component: <AddNewPortfolio />,
      },
      {
        name: "portfolio Page",
        key: "portfolios-page",
        route: "/dashboard/4-portfolio/portfolio-page",
        component: <PortfolioPage />,
      },
    ],
  },
  //-------------- Courses---------------------
  {
    type: "collapse",
    name: "Courses",
    key: "course",
    icon: <MenuBookIcon fontSize="medium" />,
    collapse: [
      {
        name: "New Course",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-Course",
        route: "/dashboard/2-courses/new-course",
        component: <AddNewBlog />,
      },
      {
        name: "Edit Course",
        key: "edit-course",
        route: "/dashboard/2-courses/edit-course",
        component: <EditBlog />,
      },
      {
        name: "Course Page",
        key: "courses-page",
        route: "/dashboard/2-courses/courses-page",
        component: <BlogsPage />,
      },
    ],
  },
  //-------------- Categories---------------------
  {
    type: "collapse",
    name: "Category",
    key: "category",
    icon: <CategoryIcon fontSize="medium" />,
    collapse: [
      {
        name: "New category",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-category",
        route: "/dashboard/6-categories/new-category",
        component: <AddNewCategory />,
      },

      {
        name: "Edit category",
        key: "edit-category",
        route: "/dashboard/2-categories/edit-categories",
        // component: <EditExam />,
      },
      {
        name: "category Page",
        key: "categorys-page",
        route: "/dashboard/4-categories/categories-page",
        // component: <categoriesPage />,
      },
    ],
  },
  //-------------- Technologies---------------------
  {
    type: "collapse",
    name: "Technologies",
    key: "Technology",
    icon: <ComputerIcon fontSize="medium" />,
    collapse: [
      {
        name: "New technology",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-technology",
        route: "/dashboard/6-technologies/new-technology",
        // component: <AddNewtechnology />,
      },

      {
        name: "Edit technology",
        key: "edit-technology",
        route: "/dashboard/2-technologies/edit-categories",
        // component: <EditExam />,
      },
      {
        name: "technology Page",
        key: "technologys-page",
        route: "/dashboard/4-technologies/technologies-page",
        // component: <technologiesPage />,
      },
    ],
  },
  //-------------- Services---------------------
  {
    type: "collapse",
    name: "Services",
    key: "category",
    icon: <DesignServicesIcon fontSize="medium" />,
    collapse: [
      {
        name: "New category",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-category",
        route: "/dashboard/6-categories/new-category",
        component: <AddNewCategory />,
      },

      {
        name: "Edit category",
        key: "edit-category",
        route: "/dashboard/2-categories/edit-categories",
        // component: <EditExam />,
      },
      {
        name: "category Page",
        key: "categorys-page",
        route: "/dashboard/4-categories/categories-page",
        // component: <categoriesPage />,
      },
    ],
  },
  //-------------- Solutions---------------------
  {
    type: "collapse",
    name: "Solutions",
    key: "category",
    icon: <LightbulbIcon fontSize="medium" />,
    collapse: [
      {
        name: "New category",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-category",
        route: "/dashboard/6-categories/new-category",
        component: <AddNewCategory />,
      },

      {
        name: "Edit category",
        key: "edit-category",
        route: "/dashboard/2-categories/edit-categories",
        // component: <EditExam />,
      },
      {
        name: "category Page",
        key: "categorys-page",
        route: "/dashboard/4-categories/categories-page",
        // component: <categoriesPage />,
      },
    ],
  },
  //-------------- Vaucher---------------------
  {
    type: "collapse",
    name: "Vaucher",
    key: "category",
    icon: <CardGiftcardIcon fontSize="medium" />,
    collapse: [
      {
        name: "New category",
        icon: <Icon fontSize="small">add_circle</Icon>,
        key: "new-category",
        route: "/dashboard/6-categories/new-category",
        component: <AddNewCategory />,
      },

      {
        name: "Edit category",
        key: "edit-category",
        route: "/dashboard/2-categories/edit-categories",
        // component: <EditExam />,
      },
      {
        name: "category Page",
        key: "categorys-page",
        route: "/dashboard/4-categories/categories-page",
        // component: <categoriesPage />,
      },
    ],
  },
];

export default routes;
