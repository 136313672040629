import { useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDDropzone from "components/MDDropzone";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function FormField({ label, ...rest }) {
  return <MDInput {...rest} label={label} variant="standard" fullWidth />;
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddNewCategory({ AddNewCategory }) {
  const [categoryData, setCategoryData] = useState({
    title: "",
    category: "",
    Description: "",
    MainImg: null,
  });

  const updateSliderData = (field, value) => {
    setCategoryData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    addHomeSliedit(categoryData);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={8} textAlign="center">
        <MDBox mb={1}>
          <MDTypography variant="h3" fontWeight="bold">
            Add New Category
          </MDTypography>
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" color="secondary">
          This information will describe more about the Category .
        </MDTypography>
      </MDBox>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Title"
                      value={categoryData.title}
                      onChange={(e) => updateSliderData("title", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Main Description"
                      value={categoryData.category}
                      onChange={(e) => updateSliderData("category", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormField
                      type="text"
                      label="Short Description"
                      value={categoryData.Description}
                      onChange={(e) => updateSliderData("Description", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Background Image
                    </MDTypography>
                    <MDDropzone
                      options={{ addRemoveLinks: true }}
                      onDrop={(files) => updateSliderData("MainImg", files[0])}
                    />
                  </Grid>
                </Grid>
                <MDBox mt={3} display="flex" justifyContent="center">
                  <MDButton variant="gradient" color="dark" onClick={handleSubmit}>
                    Publish
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

AddNewCategory.propTypes = {
  AddNewCategory: PropTypes.func.isRequired,
};

export default AddNewCategory;
